<template>
  <div
    class="app-dialog"
    @click.self="close"
  >
    <div
      class="dialog"
      @click.stop
    >
      <div
        v-if="isDisplayedCloseBtn"
        class="close"
        @click="close"
      ></div>
      <template v-if="$slots.title">
        <h1 class="title">
          <slot name="title"></slot>
        </h1>
      </template>
      <template v-if="$slots.content">
        <div class="content">
          <slot name="content"></slot>
        </div>
      </template>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  isDisplayedCloseBtn: boolean
}

interface Emits {
  (e: 'close'): void
}

withDefaults(defineProps<Props>(), {
  isDisplayedCloseBtn: false,
})

const emit = defineEmits<Emits>()

const close: () => void = () => {
  emit('close')
}
</script>

<style lang="scss" scoped>
.app-dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-dialog;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $black-transparent-5;

  > .dialog {
    position: relative;
    width: fit-content;
    height: fit-content;
    max-height: 100vh;
    overflow-y: auto;
    background-color: $white-color;
    border-radius: 10px;
    box-shadow: 0 3px 9px $black-transparent-light;
    box-sizing: content-box;

    > .close {
      position: absolute;
      top: 17px;
      right: 17px;
      z-index: $zindex-dialogBody;
      width: 40px;
      height: 40px;
      border: solid 1px $primary-color;
      box-sizing: border-box;
      border-radius: 20px;

      &::after,
      &::before {
        position: absolute;
        top: 18px;
        left: 13px;
        width: 13px;
        content: '';
        border-top: solid 2px $primary-color;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:hover {
        cursor: pointer;
      }
    }
    // 共通のタイトルstyle
    .title {
      margin: 1em 0;
      font-size: $font-size-16;
      padding-left: 50px;
      padding-top: 50px;
      padding-right: 50px;
    }
    // 共通のコンテンツstyle
    .content {
      padding-left: 50px;
      padding-bottom: 50px;
      padding-right: 50px;
      p {
        margin: 1em 0;
      }
    }
  }
}
// TODO: teleportでの調整
:global(.v-menu.v-overlay--active) {
  z-index: $zindex-dialogBody !important;
}
</style>
